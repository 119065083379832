.w-90 {
  width: 90%;
}

.py-15p {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-25p {
  padding: 25px 0;
}

.pb-25p {
  padding-bottom: 25px;
}

.pt-15p {
  padding-top: 15px;
}

.p-40p {
  padding: 40px !important;
}

.px-40p {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.pr-8p {
  padding-right: 0.5rem;
}

.flex-1 {
  flex-grow: 1;
}
.flex-shrink {
  flex-shrink: inherit;
}

.flex-wrap-enable {
  flex-wrap: wrap;
}

// .flex-nowrap {
//   flex-wrap: nowrap;
// }

.border-r-20 {
  border-radius: 20px;
}
.border-r-8 {
  border-radius: 0.5rem;
}
.min-w-150 {
  min-width: 150px;
}
.white-space-norwrap {
  white-space: nowrap !important;
}
.font-size-12 {
  font-size: 0.75rem;
}

.font-size-13 {
  font-size: 0.8rem;
}

.font-size-14 {
  font-size: 0.875rem;
}

.font-size-18 {
  font-size: 1.125rem;
}

.font-size-20 {
  font-size: 1.25rem;
}

.font-size-40 {
  font-size: 2.5rem;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.gap-20 {
  gap: 20px;
}
.gap-12 {
  gap: 12px;
}
.gap-8 {
  gap: 8px;
}

.mb-8p {
  margin-bottom: 0.5rem;
}

.ml-8p {
  margin-left: 0.5rem;
}

.mr-8p {
  margin-right: 0.5rem;
}

.mt-8p {
  margin-top: 0.5rem;
}

.mx-8p {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.white-space-pre-line {
  white-space: pre-line;
}
.flex-1 {
  flex: 1 1 0%;
}
.border-bottom-black {
  border-bottom: 1px solid #333;
}

.flex-column-center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-column-start {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.flex-column-end {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.object-fit-cover {
  object-fit: cover;
}

.max-lines-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid-3x {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}
