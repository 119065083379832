$dark-blue: #4253af;
$blur-gary: #b5b5be;
$dark-gray: #828282;
$dark-orange: #dc7921;
$deep-purple: #583bab;

.color-dark-blue {
  color: $dark-blue;
}

.bg-dark-blue {
  background: $dark-blue;
}

.color-blur-gray {
  color: $blur-gary;
}

.color-dark-gray {
  color: $dark-gray;
}

.bg-blur-gray {
  background: $blur-gary !important;
}

.color-dark-orange {
  color: $dark-orange;
}

.color-white {
  color: #fff !important;
}

.color-red {
  color: #eb5757;
}

.color-green {
  color: green;
}

.color-black {
  color: black;
}

.bg-disabled {
  background-color: #efefef;
}

.bg-table-header {
  background-color: #edf2f7;
}
