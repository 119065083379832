html {
  font-size: 16px !important;
}
body {
  font-family: 'Roboto', sans-serif !important;
  color: #1c1d21;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1c1d21;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

p,
div,
.form-control,
.btn {
  font-size: 0.875rem;
}
.btn-secondary {
  background: #f7f7f7 !important;
  border: none !important;
  &:hover {
    box-shadow: 0 3px 17px -11px #828282 !important;
  }
}
.btn-secondary:focus {
  background: #f7f7f7 !important;
}
.logo-vuvu {
  width: 170px;
  height: 26px;
}

.horizontal-menu-wrapper {
  padding-top: 0;
  position: relative;
  height: 60px;
}

.horizontal-menu .header-navbar.navbar-fixed {
  position: fixed;
  width: 100%;
}
.dropdown-menu {
  min-width: 50px;
  font-size: 0.875rem;
  .dropdown-item {
    width: 100%;
  }
}
.navbar-brand-center {
  .navbar-container {
    padding: 8px 1rem;
  }
}
.header-navbar {
  font-family: 'Roboto', sans-serif;
  min-height: inherit;
  &.navbar-horizontal {
    width: 100%;
    margin: 0;
    border-top: 1px solid #e2e2ea;
    border-bottom: 1px solid #e2e2ea;
    border-radius: 0;
  }
  &.floating-nav {
    top: 66px;
  }
  .brand-logo {
    flex-basis: 240px;
  }
  .navbar-container {
    max-width: 1308px;
    margin: auto !important;
    .navbar-nav {
      flex-direction: row;
      gap: 12px;
    }
    .dropdown-user a.dropdown-user-link {
      .avatar-header {
        position: relative;
        display: flex;
        height: 38px;
        width: 38px;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 0.875em;
        margin-right: 12px;
        color: #fff;
      }
      .avatar-header::after {
        content: '';
        position: absolute;
        background: #faa52d;
        height: 38px;
        width: 38px;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 50%;
      }
      .user-name {
        font-size: 0.875em;
        color: #171725 !important;
        margin-left: 0;
      }
      .user-status {
        font-size: 0.75em;
        color: #92929d !important;
        line-height: 16px;
      }
      .user-info {
        padding-right: 10px;
        &::after {
          display: inline-block;
          margin-left: 0.255em;
          vertical-align: 0.255em;
          content: '';
          border-top: 7px solid #92929d;
          border-right: 6px solid transparent;
          border-bottom: 0;
          border-left: 6px solid transparent;
          position: absolute;
          right: 0;
          top: 44%;
        }
      }
    }
    .dropdown-notification {
      background: #f2f2f2;
      height: 38px;
      width: 38px;
      border-radius: 50%;
      .bell {
        padding-top: 8px;
        padding-left: 2px;
        .badge.badge-up {
          top: -10px;
        }
      }
    }
    .dropdown-projects {
      flex-basis: 240px;
      button {
        color: #171725 !important;
        font-weight: 600;
        background-color: #f7f7f7 !important;
        border-color: #f7f7f7 !important;
        width: 100% !important;
        text-align: center !important;
      }
    }
    .header-search {
      flex-basis: 505px;
      .input-group {
        padding: 0 !important;
        background: #f7f7f7;
        border-color: #f7f7f7 !important;
        border-radius: 8px;
      }
      input {
        height: inherit !important;
        padding: 7px 0 7px 14px !important;
        background: #f7f7f7;
        border-color: #f7f7f7 !important;
        &:focus {
          box-shadow: none;
          outline: 0;
        }
      }
      .input-group-text {
        background: #f7f7f7;
        border-color: #f7f7f7 !important;
        border-radius: 8px;
      }
    }
  }
  .main-menu-content {
    overflow-x: auto;
    ul {
      li {
        white-space: nowrap;
        a.nav-link {
          color: #1c1d21 !important;
          padding: 6px 0.5rem !important;
        }
        button.btn-none {
          color: #1c1d21 !important;
          padding: 6px 0.5rem !important;
          font-weight: 400;
          align-items: center;
          display: flex;
        }
        a.nav-link.active {
          background: #f2994a;
          color: #fff !important;
          border-radius: 8px !important;
        }
      }
      li.dropdown {
        position: static;
        .btn.active {
          background: #f2994a;
          color: #fff !important;
          border-radius: 8px !important;
        }
        .dropdown-item {
          padding: 5px 10px;
        }
      }
    }
    &::-webkit-scrollbar-track:hover {
      opacity: 1;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 2px #555555;
      background-color: #f5f5f5;
      display: none;
      opacity: 0;
    }

    &::-webkit-scrollbar {
      width: 3px;
      right: 5px;
      top: 0px;
      position: absolute;
      border-radius: 7px;
      bottom: 0px;
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      width: 2px;
      height: 20px;
      margin-left: -3px;
      position: absolute;
      border-radius: 20px;
      opacity: 1;
      top: 0;
      background-color: rgba(0, 0, 0, 0.35);
    }
  }
}
.dashboard {
  .btn-secondary {
    background: #fff !important;
    span {
      color: #6e6b7b !important;
    }
  }
}
.main-menu-content {
  .dropdown-menu {
    top: 48px !important;
  }
}

.content.app-content {
  padding-top: 64px !important;
  margin-left: 0;
}

.content-wrapper {
  margin: auto !important;
  padding: 1rem 1rem;
}

.btn {
  padding: 7px 15px;
  border-radius: 8px;
}
.dropdown-white {
  button {
    color: #808191 !important;
    background: #fff !important;
    border: none;
  }
}

.content-shadow {
  box-shadow: 0 4px 24px 0 #22292f1a;
}

.content-public-wrapper {
  border-radius: 20px;
  background: white;
  max-width: 1170px;
  margin: auto;
  margin-top: 60px;
  padding: 40px 0;
}

$bci-green: #00a94b;
$bci-red: #f33d33;
$bci-blue: #007ebf;
$bci-yellow: #fbed00;
.loading-box {
  z-index: 9999;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.3);
  height: 100%;
  width: 100%;
  text-align: center;
  top: 0;

  .spinner-list {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 400px;
    height: 100px;
    text-align: center;
    position: absolute;
    & > div {
      margin: 0px 5px;
    }
  }
}
.empty-datatable {
  min-height: 400px;
  display: flex;
  align-items: center;
}
.flatpickr-input-custom {
  border: 1px solid #d8d6de;
  border-radius: 0.5rem;
}

@media screen and (max-width: 1200px) {
  .horizontal-menu-wrapper {
    padding-top: 62px;
  }
}

.upload-btn,
.upload-btn:hover {
  color: #0062ff;
  background-color: rgba(0, 98, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-date-range {
  background: #f7f7f7 !important;
  min-width: 160px;
  padding: 10px 12px !important;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f1c40f;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.circle:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}