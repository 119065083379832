@font-face {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 100;
  src: local('Lexend Deca'), url('../fonts/Lexend-Deca/LexendDeca-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 200;
  src: local('Lexend Deca'), url('../fonts/Lexend-Deca/LexendDeca-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 300;
  src: local('Lexend Deca'), url('../fonts/Lexend-Deca/LexendDeca-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 400;
  src: local('Lexend Deca'), url('../fonts/Lexend-Deca/LexendDeca-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 500;
  src: local('Lexend Deca'), url('../fonts/Lexend-Deca/LexendDeca-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 600;
  src: local('Lexend Deca'), url('../fonts/Lexend-Deca/LexendDeca-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 700;
  src: local('Lexend Deca'), url('../fonts/Lexend-Deca/LexendDeca-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 800;
  src: local('Lexend Deca'), url('../fonts/Lexend-Deca/LexendDeca-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lexend Deca';
  font-style: normal;
  font-weight: 900;
  src: local('Lexend Deca'), url('../fonts/Lexend-Deca/LexendDeca-Black.ttf') format('truetype');
}
