.rdt_Table {
  border-bottom: 1px solid #e0e0e0;

  .table {
    margin-bottom: 0;

    //  height: 100%;
    td {
      padding: 10px;
      background: #fff;
    }
  }
}

.rdt_TableHeadRow {
  font-size: 0.75rem;
  color: #828282;
  text-transform: uppercase;

  .rdt_TableCol {
    font-size: 0.75rem;
    background: #edf2f7;
    padding: 0;
    text-align: center;
  }

  .rdt_TableCol_Sortable {
    width: 100%;
    font-size: 0.75rem;

    &>div {
      font-size: 0.75rem;
      width: 100%;
      word-wrap: break-word;
      white-space: normal;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
    }
  }
}

.rdt_TableBody {
  .rdt_TableCell {
    //align-items: flex-start;
    align-items: center;
    padding: 10px;
    color: #4f4f4f;
    border-right: 1px solid #e0e0e0;

    &:last-child {
      border-right: none;
    }
  }

  .rdt_TableCell div:first-child {
    white-space: inherit;
  }

  .table.table-bordered {
    display: inline-table;
    height: 100%;
  }
}

.custom-paginate {
  background: #edf2f7;

  .pagination {
    align-items: center;
    margin: 12px 0;

    .page-item {
      background-color: #edf2f7;

      .page-link {
        background-color: #edf2f7;
      }

      &:first-child {
        .page-link {
          background: #fff !important;
          border-radius: 4px;
          padding: 5px 5px 5px 2px;
        }
      }

      &:last-child {
        .page-link {
          background: #fff !important;
          border-radius: 4px;
          padding: 5px 2px 5px 5px;
        }
      }
    }

    .page-item.active {
      .page-link {
        color: #0062ff !important;
      }
    }
  }

  .btn-secondary {
    margin: 0 30px 0 20px;
    background: #fff !important;
    color: #92929d !important;
    padding: 10px !important;
  }
}